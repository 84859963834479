import React from 'react';
import banner from '../../banner.png'
import fire from '../../flames.gif'

import {Link } from "react-router-dom";

class Banner extends React.Component {
    render() {
        return <div>
            <header className="App-header">
                <img src={fire} className="App-bannerbackground" />
                <img src={banner} className="App-banner" alt="logo" />
            </header>
            <div id="rectangle">
                <center>
                    <Link to="/">
                        <button className="button button1">Home</button>
                    </Link >
                    <Link to="/Artists">
                        <button className="button button1">Artists</button>
                    </Link>
                    <Link to="/GWWorld">
                        <button className="button button1">Ghostwork World</button>
                    </Link>
                </center>
            </div>
        </div>;
    }
}

export default Banner;