import React from 'react';
import SpotifyPlayer from 'react-spotify-player';

import fire from '../../flames.gif'

import ailogo from '../../Profiles/ai_logo.jpg'
import caspianlogo from '../../Profiles/caspiankhw_logo.jpg'
import faustlogo from '../../Profiles/faustmcree_logo.jpg'
import solaslogo from '../../Profiles/solas_logo.jpg'
import timlogo from '../../Profiles/timspiker_logo.jpg'
import moxlogo from '../../Profiles/mox_logo.jpg'
import raynelogo from '../../Profiles/rayne_logo.jpg'
import tardigrade from '../../Profiles/tardigrade_logo.jpg'
import xylelogo from '../../Profiles/xyle_logo.jpg'
import relevant from '../../Profiles/relevantresonance_logo.jpg'
import colondee from '../../Profiles/colondee_logo.jpg'
import kltldr from '../../Profiles/kltldr_logo.jpg'
import synthronia from '../../Profiles/synthronia_logo.jpg'
import terminal from '../../Profiles/terminal_logo.jpg'
import greymtr from '../../Profiles/greymtr_logo.jpg'
import zlader from '../../Profiles/zlader_logo.jpg'

import bandcamp_icon from '../../Socials/bandcamp.png'
import facebook_icon from '../../Socials/facebook.png'
import instagram_icon from '../../Socials/instagram.png'
import itunes_icon from '../../Socials/itunes.png'
import soundcloud_icon from '../../Socials/soundcloud.png'
import spotify_icon from '../../Socials/spotify.png'


function Profile({ logo, name, spot, spot_link, cloud_link, camp_link, book_link, gram_link, tunes_link }) {
    return <div className='profile-row' >

        <img src={fire} className={name + " profile-background"} />

        <div className='profile-icon-div'>
            <center>
                <img className='profile-icon' src={logo} />
            </center>
        </div>

        <div className='spotify-div'>
            <SpotifyPlayer uri={spot} size={{ width: '100%', height: '100%' }} theme='1' view="list" />
        </div>

        <div className='socials-div'>

            <a href={spot_link} target="_blank">
                <img src={spotify_icon} className='socials-icon spotify' />
            </a>

            <a href={cloud_link} target="_blank">
                <img src={soundcloud_icon} className='socials-icon soundcloud' />
            </a>

            <a href={camp_link} target="_blank">
                <img src={bandcamp_icon} className='socials-icon bandcamp' />
            </a>

            <a href={book_link} target="_blank">
                <img src={facebook_icon} className='socials-icon facebook' />
            </a>

            <a href={gram_link} target="_blank">
                <img src={instagram_icon} className='socials-icon instagram' />
            </a>

            <a href={tunes_link} target="_blank">
                <img src={itunes_icon} className='socials-icon itunes' />
            </a>

        </div>
    </div>;
}

class Artists extends React.Component {
    render() {
        return <div className="artists-layout">

            <Profile 
                logo={ailogo} 
                name="astralincantations" 
                spot="spotify:artist:2I2AxFo15KWoInRxnJXqn0"
                spot_link={"https://open.spotify.com/artist/2I2AxFo15KWoInRxnJXqn0"}
                cloud_link={"https://soundcloud.com/astral-incantations"}
                camp_link={"https://astral-incantations.bandcamp.com/album/exe"}
                book_link={"https://www.facebook.com/Mystic.Audio"}
                gram_link={"https://www.instagram.com/astral.incantations/"}
                tunes_link={"https://music.apple.com/us/artist/astral-incantations/1499295303"}
            />

            <Profile 
                logo={raynelogo} 
                name="rayne" 
                spot="spotify:artist:0oaTxXMT68Bd6kYmvUBi1y" 
                spot_link={"https://open.spotify.com/artist/0oaTxXMT68Bd6kYmvUBi1y"}
                cloud_link={"https://soundcloud.com/rayne-reznor"}
                camp_link={"https://raynereznor.bandcamp.com/album/elizabeth-single"}
                book_link={"https://www.facebook.com/rayne.reznor.7"}
                gram_link={"https://www.instagram.com/raynereznor/"}
                tunes_link={"https://music.apple.com/us/artist/rayne-reznor/1487751341"}
            />

            <Profile 
                logo={caspianlogo} 
                name="caspiankhw" 
                spot="spotify:artist:1maUg1DT56NbHv7ip1VSmT"
                spot_link={"https://open.spotify.com/artist/1maUg1DT56NbHv7ip1VSmT"}
                cloud_link={"https://soundcloud.com/caspiankhw"}
                camp_link={"https://caspiankhw.bandcamp.com/"}
                book_link={"https://www.facebook.com/CaspianKhwX"}
                gram_link={"https://www.instagram.com/caspiankhw/"}
                tunes_link={"https://music.apple.com/us/artist/caspian-khw/1460284800"}
            />

            <Profile 
                logo={zlader} 
                name="zlader" 
                spot="spotify:artist:1Wl3AgiSD3BKRQo0clMReu" 
                spot_link={"https://open.spotify.com/artist/1Wl3AgiSD3BKRQo0clMReu"}
                cloud_link={"https://soundcloud.com/zlader"}
                camp_link={"https://zlader.bandcamp.com/"}
                book_link={""}
                gram_link={"https://www.instagram.com/zlader_official/"}
                tunes_link={"https://music.apple.com/us/artist/zlader/1590250835"}
            />

            <Profile 
                logo={faustlogo} 
                name="faust" 
                spot="spotify:artist:554UOuPoso11p0I2qIJY3I" 
                spot_link={"https://open.spotify.com/artist/554UOuPoso11p0I2qIJY3I"}
                cloud_link={""}
                camp_link={"https://faustmccree.bandcamp.com/album/faust-mccrees-bizarre-requiem"}
                book_link={""}
                gram_link={""}
                tunes_link={"https://music.apple.com/us/artist/faust-mccree/1523120691"}
            />

            <Profile 
                logo={moxlogo} 
                name="mox" 
                spot="spotify:artist:1Or926v4QrR7uOuHDSB6FA"
                spot_link={"https://open.spotify.com/artist/1Or926v4QrR7uOuHDSB6FA"}
                cloud_link={"https://soundcloud.com/moxinfernum"}
                camp_link={"https://moxinfernum.bandcamp.com/track/wrath"}
                book_link={""}
                gram_link={"https://www.instagram.com/moxinfernum/"}
                tunes_link={"https://music.apple.com/us/artist/mox-infernum/1588353186"}
            />

            <Profile 
                logo={tardigrade} 
                name="tardigrade" 
                spot="spotify:artist:4IhNvHCmVaQO0Iv1zTAiRF"
                spot_link={"https://open.spotify.com/artist/4IhNvHCmVaQO0Iv1zTAiRF"}
                cloud_link={""}
                camp_link={"https://electrictardigrade.bandcamp.com/"}
                book_link={"https://www.facebook.com/profile.php?id=100084738035370"}
                gram_link={"https://www.instagram.com/red_eye1982/"}
                tunes_link={"https://music.apple.com/us/artist/electric-tardigrade/1571017699"}
            />

<Profile 
                logo={xylelogo} 
                name="xyle" 
                spot="spotify:artist:0EFx5YL9jxGONVTTtICM9o"
                spot_link={"https://open.spotify.com/artist/0EFx5YL9jxGONVTTtICM9o"}
                cloud_link={"https://soundcloud.com/xylemusic"}
                camp_link={"https://xyle.bandcamp.com/"}
                book_link={"https://www.facebook.com/xylepage"}
                gram_link={"https://www.instagram.com/xylemusic/"}
                tunes_link={"https://music.apple.com/us/artist/xyle/1440046819"}
            />

            <Profile 
                logo={relevant} 
                name="relevant" 
                spot="spotify:artist:1RU605KzqdMT8BjiBM3Tqc" 
                spot_link={"https://open.spotify.com/artist/1RU605KzqdMT8BjiBM3Tqc"}
                cloud_link={"https://soundcloud.com/relevant_resonance"}
                camp_link={"https://relevantresonance.bandcamp.com/"}
                book_link={"https://www.facebook.com/RelevantResonance"}
                gram_link={"https://www.instagram.com/relevant_resonance/"}
                tunes_link={"https://music.apple.com/us/artist/relevant-resonance/1472937989"}
            />

            <Profile 
                logo={colondee} 
                name="colondee" 
                spot="spotify:artist:44i24JEla46GcRkl7F3Nn1" 
                spot_link={"https://open.spotify.com/artist/44i24JEla46GcRkl7F3Nn1"}
                cloud_link={"https://soundcloud.com/colondeee"}
                camp_link={"https://colondee.bandcamp.com/"}
                book_link={"https://www.facebook.com/ColonDeee"}
                gram_link={"https://www.instagram.com/colondeeofficial/"}
                tunes_link={"https://music.apple.com/us/artist/colondee/1517882655"}
            />

            <Profile 
                logo={kltldr} 
                name="kltldr" 
                spot="spotify:artist:5ALGdNYqDhQCDuc1uwORRA" 
                spot_link={"https://open.spotify.com/artist/5ALGdNYqDhQCDuc1uwORRA"}
                cloud_link={"https://soundcloud.com/kristopherakin"}
                camp_link={"https://kvltldr.bandcamp.com/"}
                book_link={"https://www.facebook.com/profile.php?id=100090703753296"}
                gram_link={"https://www.instagram.com/blackmassproductions/ "}
                tunes_link={""}
            />

            <Profile 
                logo={synthronia} 
                name="synthronia" 
                spot="spotify:artist:6kNC1fgIaqgT179dgSt071" 
                spot_link={"https://open.spotify.com/artist/6kNC1fgIaqgT179dgSt071"}
                cloud_link={"https://soundcloud.com/synthronia-rukary"}
                camp_link={"https://synthronia.bandcamp.com/"}
                book_link={"https://www.facebook.com/synthronia"}
                gram_link={"https://www.instagram.com/synthronia/"}
                tunes_link={"https://music.apple.com/us/artist/synthronia/1521903623"}
            />

            <Profile 
                logo={terminal} 
                name="terminal" 
                spot="spotify:artist:2NVCjUcEYwm9yM3wVRH2m4" 
                spot_link={"https://open.spotify.com/artist/2NVCjUcEYwm9yM3wVRH2m4"}
                cloud_link={"https://soundcloud.com/terminal-arbiter"}
                camp_link={"https://terminalarbiter.bandcamp.com/track/reverse-cross-entropy"}
                book_link={"https://www.facebook.com/TerminalArbiter"}
                gram_link={"https://www.instagram.com/terminal.arbiter/"}
                tunes_link={"https://music.apple.com/us/artist/terminal-arbiter/1499297913"}
            />

            <Profile 
                logo={greymtr} 
                name="greymtr" 
                spot="spotify:artist:0ty39LZwPsjEA43TF3iGDK" 
                spot_link={"https://open.spotify.com/artist/0ty39LZwPsjEA43TF3iGDK"}
                cloud_link={"https://soundcloud.com/user-508568539"}
                camp_link={"https://greymtr.bandcamp.com/"}
                book_link={"https://www.facebook.com/greymtr"}
                gram_link={"https://www.instagram.com/grey_mtr/"}
                tunes_link={"https://music.apple.com/us/artist/grey-mtr/1540995186"}
            />
        </div>;
    }
}

export default Artists;

