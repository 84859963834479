import './App.css';
import './Artists.css';
import './Socials.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Banner from './Components/Pages/Banner.js'
import Home from './Components/Pages/Home.js'
import Artists from './Components/Pages/Artists.js'
import World from './Components/Pages/World.js'

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Routes>         
          <Route path="/" element={<div><Banner/><Home/></div>}/>
          <Route path="Artists" element={<div><Banner/><Artists/></div>}/>
          <Route path="GWWorld" element={<div><Banner/><World/></div>}/>
          <Route path="*" element={<div><Banner/><Home/></div>}/>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
