import React from 'react';
import SpotifyPlayer from 'react-spotify-player';
import '../../App.css';
import fire from '../../flames.gif'


class Home extends React.Component {
    render() {
        return <div className='home'>
            <div className="profile-column">
                <img src={fire} className={"who profile-background"} />
                <div className='App-textblock-div'>
                    <center>
                        <h1>● A Canadian Music Label ●</h1>
                        <h1>Proudly presenting talent</h1>
                        <h1>from around the world</h1>
                        <h1>● Music Made Magic ●</h1>
                    </center>
                </div>
            </div>
            
            <div className='home-spotify'>
                <SpotifyPlayer uri={"spotify:playlist:0LP7ea5a5WOEi9bc2JCIiO"} size={{ width: '100%', height: '500vh' }} theme='black' view="list" />
            </div>       

        </div>;
    }
}

export default Home;